<template>
     <div>
            <button class="btn px-2 py-2  account-button" :disabled="isLoading" v-bind="$attrs" @click="$emit('click')">
              <span v-if="!isLoading"><slot>
                Button
              </slot></span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
</template>
<script>


export default ({
  props:{
    isLoading:Boolean
  }
})
</script>
<style scoped>
.account-button{
    background: #FFA201;
    color:#fff;
    border-radius: 8px;
    min-width: 119px;
}
.account-button:not(:disabled):not(.disabled):active {
     box-shadow: inset 0 0 10.2px 0.5rem rgba(80, 58, 60, 0.25)!important;
}
.account-button:active{
  
}
</style>
